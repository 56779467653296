import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ProblematicSectorService {
  private apiurl = '';

  constructor(
    private http: HttpClient,
  ) {
    this.apiurl = environment.api + 'app/v1/problematic-sector/';
  }

  list(warehouseId = null) {
    let url = this.apiurl + 'list';
    if (null !== warehouseId) {
      url += '?warehouse_id=' + warehouseId;
    }

    return this.http.get(url);
  }

  resolve(form) {
    return this.http.put(
      this.apiurl + 'resolve',
      form,
      {observe: 'response'}
    );
  }
}
