<script src="../../../services/problems/problems.service.ts"></script>
<div class="uk-grid">
  <div class="uk-width-1-1 navbar">
    <p class="nav-text">
      Obsługa sektorów przesyłek problematycznych
    </p>
  </div>
  <div class="uk-width-1-1 inner-shadow">
    <div class="uk-card uk-card-default uk-card-body uk-margin-medium-right uk-margin-medium-top">
      <div class="uk-width-1-1@l">
        <form class="" #parcelForm="ngForm" (ngSubmit)="scan()">
          <div *ngIf="null !== form.problematic_sector_id" class="uk-grid">
            <div>
              <label>{{'Sektor problematyczny' | translate}}</label>
              <input name="barcode"
                     class="uk-input"
                     type="text"
                     required
                     value="[{{problematicSector.code}}] {{problematicSector.name}}"
                     disabled
              >
            </div>
            <div>
              <label>Status</label>
              <select class="uk-select" [(ngModel)]="form.status_type_id" name="status_type_id" (change)="setFocusOnBarcodeInput()">
                <option *ngFor="let statusType of statusTypes" value="{{statusType.id}}">{{statusType.name}}</option>
              </select>
            </div>
          </div>
          <hr>
          <div class="uk-grid">
            <div class="uk-width-1-3">
              <div>
                <label *ngIf="null === form.problematic_sector_id">{{'Sektor problematyczny' | translate}}</label>

                <label *ngIf="null !== form.problematic_sector_id">{{'Numer przesyłki' | translate}}</label>
                <input name="barcode"
                       class="uk-input"
                       type="text"
                       required
                       [(ngModel)]="form.barcode"
                       #barcodeInput
                >
              </div>
            </div>
          </div>

        </form>
        <div class="uk-margin-top uk-grid">
          <div size="uk-width-1-5">
            <button class="uk-button uk-button-primary" (click)="scan()">
              Ustaw
            </button>
          </div>
          <div size="uk-width-1-5" *ngIf="null !== form.problematic_sector_id">
            <button class="uk-button uk-button-danger" (click)="reset()">
              Resetuj
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
