import {Component, Directive, ElementRef, HostListener, OnDestroy, OnInit, Renderer2, ViewChild} from '@angular/core';
import {ToastsService} from '../../../services/toasts.service';
import {environment} from '../../../../environments/environment';
import {KeyCode} from "../../../enums/key-code";
import {UserModel} from "../../../models/user.model";
import {AuthService} from "../../../services/auth/auth.service";
import {TagsService} from "../../../services/tags/tags.service";
import {Router} from "@angular/router";
import {ProblematicSectorService} from "../../../services/problematic-sector/problematic-sector.service";
import {ParcelsService} from "../../../services/parcels/parcels.service";

@Component({
  selector: 'app-resolve-problematic',
  templateUrl: './resolve-problematic.component.html',
  styleUrls: ['./resolve-problematic.component.scss']
})
export class ResolveProblematicComponent implements OnInit, OnDestroy {
  @ViewChild('barcodeInput') barcodeInput: ElementRef;
  @ViewChild('chosenClientNameInput') chosenClientNameInput: ElementRef;
  @ViewChild('postalCodeInput') postalCodeInput: ElementRef;
  @ViewChild('weightInput') weightInput: ElementRef;

  apiurl: string = null;

  parcelModel = null;

  statusTypes: any[] = [];
  clientName: string = '(kliknij aby wybrać)';

  sector_name = null;
  barcode = null;

  form = {
    barcode: null,
    problematic_sector_id: null,
    status_type_id: null,
  };

  problematicSector = null;

  constructor(
    private parcelsService: ParcelsService,
    private toastsService: ToastsService,
    private authService: AuthService,
    private problematicSectors: ProblematicSectorService,
    private router: Router,
  ) {
    this.apiurl = environment.api;
  }

  ngOnInit() {
    this.keyReading();
    this.setFocusOnBarcodeInputBackgroundTask();
    setTimeout(
      () => {
        this.setFocusOnBarcodeInput();
      }, 0
    );
    this.setWorkstation();
  }

  scan() {
    this.problematicSectors.resolve(this.form).subscribe(
      (response: any) => {
        console.log(response.body);
        console.log(response.status);
        switch (response.status) {
          case 200:
            this.toastsService.showMessage('Pomyślnie nadano status!', 's');
            break;
          case 202:
            this.problematicSector = response.body.sector;
            this.form.problematic_sector_id = this.problematicSector.id;
            this.toastsService.showMessage('Pomyślnie ustawiono sektor!', 's');
            break;
          case 205:
            this.setWorkstation();
            this.toastsService.showMessage('Pomyślnie ustawiono stanowisko!', 's');
            break;
        }

        this.form.status_type_id = null;
        this.form.barcode = null;
      },
      (error: any) => {
        switch (error.status) {
          case 403:
            this.toastsService.showMessage('Jesteś zalogowany na innym stanowisku!','e');
            return;
        }
      }
    );
  }

  ngOnDestroy() {
    document.removeEventListener('keyup', this.listenerAction, false);
  }

  keyReading() {
    document.addEventListener('keyup', this.listenerAction, false);
  }


  setFocusOnBarcodeInputBackgroundTask() {
    setTimeout(
      () => {
        this.setFocusOnBarcodeInput();
        this.setFocusOnBarcodeInputBackgroundTask();
      },
      2000
    );
  }

  setFocusOnBarcodeInput() {
    this.barcodeInput.nativeElement.focus();
  }

  listenerAction = (e: KeyboardEvent) => {
    return;
  }

  setWorkstation(): void {
    this.authService.whoami().subscribe((userModel: UserModel) => {
      if (null === userModel.workstation) {
        this.router.navigateByUrl('/set-workstation');
        this.toastsService.showMessage('Przed rozpoczęciem pracy musisz zeskanować stanowisko!', 'e');
        return;
      }

      this.workstationTypeId = userModel.workstation.type.id;
      this.getStatusTypes(this.workstationTypeId);
    });
  }

  getStatusTypes(workstationTypeId): void {
    this.form.problematic_sector_id = null;
    this.parcelsService.listWorkstationTypeAvailableStatusTypes(workstationTypeId).subscribe(
      (response: any) => {
        this.statusTypes = response;
      }
    );
  }

  workstationTypeId = null;

  reset() {
    this.form = {
      barcode: null,
      status_type_id: null,
      problematic_sector_id: null,
    };
  }
}
