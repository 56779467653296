<script src="../../../services/problems/problems.service.ts"></script>
<div class="uk-grid">
  <div class="uk-width-1-1 navbar">
    <p class="nav-text">
      {{'create_parcel_from_number' | translate}}
    </p>
  </div>
  <div class="uk-width-1-1 inner-shadow">
    <div class="uk-card uk-card-default uk-card-body uk-margin-medium-right uk-margin-medium-top">
      <div class="uk-width-1-1@l">
        <form class="" #parcelForm="ngForm" (ngSubmit)="updateStatus()">
          <div class="uk-grid">
            <div class="uk-width-1-3">
              <div>
                <label>{{'parcel_number' | translate}}</label>
                <input name="number" class="uk-input" type="text" required [(ngModel)]="this.statusModel.barcode" #barcodeInput>
              </div>
            </div>
            <div class="uk-width-1-3">
              <div class="uk-button uk-button-primary padding-horizontal-small mt-23">
                <span uk-icon="play" title="Wyłącz ręczne skanowanie" *ngIf="handwriting" (click)="switchHandWriting()"></span>
                <span uk-icon="pencil" title="Włącz ręczne skanowanie" *ngIf="!handwriting" (click)="switchHandWriting()"></span>
              </div>
            </div>
          </div>
          <div class="uk-grid uk-margin-remove-top">
            <div class="uk-width-1-3@m">
              <label>{{'choose_status' | translate}}</label>
              <select name="id_status_type" required class="uk-input" (change)="statusChanged()" [(ngModel)]="statusModel.id_status_type">
                <option *ngFor="let statusType of statusTypes" value="{{statusType.id}}">{{statusType.displayed_name}}
                </option>
              </select>
            </div>

            <div class="uk-width-1-3@m">
              <label>{{'when' | translate}}</label>
              <input type="datetime-local" autocomplete="none" name="when" [(ngModel)]="statusModel.when" required disabled
                     data-uk-timepicker class="uk-input">
            </div>

            <div class="uk-width-1-3@m">
              <label>{{'where' | translate}}</label>
              <input type="text" autocomplete="none" name="where" [(ngModel)]="statusModel.where" required
                     class="uk-input">
            </div>

            <div class="uk-width-1-3@m" *ngIf="'known_problem_detected' === this.statusSystemId">
              <label>{{'Wbierz regułę problemu' | translate}}</label>
              <select name="id_problematic_sector" required class="uk-input" [(ngModel)]="statusModel.id_problematic_sector">
                <option *ngFor="let sector of problematicSectors" value="{{sector.id}}">[{{sector.code}}] {{sector.name}}
                </option>
              </select>
            </div>
          </div>
          <div class="uk-margin-top">
            <button [disabled]="!parcelForm.valid" type="submit" class="uk-button uk-button-primary">
              Przyjmij
            </button>
          </div>
        </form>
      </div>
      <hr>
      <div *ngIf="parcelModel!==null" class="uk-width-1-1@l">
        <form #parcelDimensionsForm="ngForm" (ngSubmit)="updateDimensions()">
          <div class="uk-grid">
            <div class="uk-width-1-6@m">
              <label>{{'W. wolumetryczna' | translate}}</label>
              <input name="volumetric_weight" class="uk-input" type="number" step="0.01" required disabled [(ngModel)]="parcelModel.volumetric_weight">
            </div>
            <div class="uk-width-1-6@m">
              <label>{{'accounted_weight' | translate}}</label>
              <input name="weight_value" class="uk-input" type="number" step="0.01" required disabled [(ngModel)]="parcelModel.weight_value">
            </div>
            <div class="uk-width-1-6@m">
              <label>{{'weight' | translate}}</label>
              <input name="weight" class="uk-input" type="text" step="0.01" required (keyup)="scanningLocked = true" [(ngModel)]="parcelModel.weight">
            </div>
            <div class="uk-width-1-6@m">
              <label>{{'height' | translate}}</label>
              <input name="height" class="uk-input" type="text" step="0.01" required (keyup)="scanningLocked = true"  [(ngModel)]="parcelModel.height">
            </div>
            <div class="uk-width-1-6@m">
              <label>{{'width' | translate}}</label>
              <input name="width" class="uk-input" type="text" step="0.01" required (keyup)="scanningLocked = true"  [(ngModel)]="parcelModel.width">
            </div>
            <div class="uk-width-1-6@m">
              <label>{{'length' | translate}}</label>
              <input name="length" class="uk-input" type="text" step="0.01" required (keyup)="scanningLocked = true" #lenghtInput [(ngModel)]="parcelModel.length">
            </div>
          </div>
          <div class="uk-margin-top">
            <button type="submit" class="uk-button uk-button-primary">
              Zapisz wymiary
            </button>
          </div>
        </form>
      </div>

      <div class="uk-width-1-1@l uk-margin-top">
        <table class="uk-table uk-width-1-1 uk-table-hover uk-table-divider" *ngIf="statuses.length > 0">
          <thead>
          <tr>
            <th>{{'date' | translate}}</th>
            <th>{{'status' | translate}}</th>
            <th></th>
          </tr>
          </thead>
          <tbody *ngIf="statuses?.length > 0">
          <tr *ngFor="let status of statuses">
            <td><kbd>{{ status.when }}</kbd></td>
            <td>
              {{ status.displayed_name }}
              <span *ngIf="status.where != null">({{ (status.where).replace('or. ', '') }})</span>
              <small title="Login użytkownika który nadał status"><br>{{status.email}}</small>
            </td>
            <td>

              <!-- todo fix me? czy usunąc
              <button class="uk-button padding-horizontal-small" style="float: right; margin-top: 0" [disabled]="parcelModel !== null"
                      [attr.uk-tooltip]="'Usuń status'" (click)="onStatus(status.id)"><span
                uk-icon="trash"></span>
              </button> -->

            </td>
          </tr>
          </tbody>
        </table>
      </div>


    </div>

  </div>
</div>

<div id="problematicSectorChooseModal" class="problematicSectorChooseModal" uk-modal>
  <div class="uk-modal-dialog">
    <button class="uk-modal-close-default" type="button" uk-close (click)="closeProblematicSectorChooseModal()"></button>
    <div class=" uk-modal-body">
      <div class="uk-grid" >
        <div class="uk-width-1-1">
          <label>{{'Wbierz problem' | translate}}</label>
          <select name="id_problematic_sector" required class="uk-input" [(ngModel)]="statusModel.id_problematic_sector">
            <option *ngFor="let sector of problematicSectors" value="{{sector.id}}">[{{sector.code}}] {{sector.name}}
            </option>
          </select>

        </div>
        <div class="uk-width-1-2 mt-23">
          <button class="uk-button uk-button-primary" type="button" (click)="updateStatus();">OK</button>
        </div>
        <div class="uk-width-1-2 mt-23 ">
          <button class="uk-button uk-button-danger"
                  type="button" (click)="resetProblematic()">
            Rezygnuję
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

