import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class WorkstationService {
  private apiurl = '';

  constructor(
    private http: HttpClient,
  ) {
    this.apiurl = environment.api + 'app/v1/warehouse/';
  }

  listTypes() {
    return this.http.get(this.apiurl+'workstation-types');
  }

  listWorkstations(warehouseId, typeId ) {
    return this.http.get(this.apiurl + warehouseId + '/workstation/by-type/' + typeId);
  }

  endWorkflow(type) {
    const url = this.apiurl + 'workflow';

    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: {
        type,
      },
    };

    return this.http.delete(url, options);
  }
}
